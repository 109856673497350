import { ColorTokens, EverColor, PopoverPlacement } from "design-system";
import type AssignmentGroup from "Everlaw/AssignmentGroup";
import { batchFetcher, cachedFetcher } from "Everlaw/BatchFetcherUtils";
import { EVERID, setEverId } from "Everlaw/EverAttribute/EverId";
import { Recommendations } from "Everlaw/SmartOnboarding/RecommendationConstants";
import Base = require("Everlaw/Base");
import Is = require("Everlaw/Core/Is");
import DocumentGroupType = require("Everlaw/DocumentGroupType");
import Dom = require("Everlaw/Dom");
import Rest = require("Everlaw/Rest");
import Task = require("Everlaw/Task");
import Dialog = require("Everlaw/UI/Dialog");
import QueryDialog = require("Everlaw/UI/QueryDialog");
import UI_Validated = require("Everlaw/UI/Validated");
import User = require("Everlaw/User");
import UserObject = require("Everlaw/UserObject");
import Util = require("Everlaw/Util");

interface Counts {
    numReviewed: number;
    size: number;
}

class Assignment extends UserObject {
    get className() {
        return "Assignment";
    }
    override id: Assignment.Id;
    name: string;
    group: AssignmentGroup.Id;
    assignee: User;
    owner: User;
    created: number;
    // These values are serialized on page load directly from cached values in the database.
    // They may be stale and do not account for per-doc permissions.
    // refresh() will update this value, accounting for per-doc permissions, and publish update through Base.
    numReviewed: number;
    size: number;
    batchSize: number;
    batchId: number;
    reviewCriteria: any[]; // EQL json
    isGrouped: boolean;
    groupBy: DocumentGroupType.Id; // can be null
    sort: string;
    savedLayoutId: number;

    static backingCache = new WeakMap<Assignment, Promise<Counts>>();
    static countFetcher = batchFetcher(getCountsById);
    static countCache = cachedFetcher((assignment) => {
        return Assignment.countFetcher(assignment).then((counts) => {
            counts && assignment.updateCounts(counts);
            return counts;
        });
    }, Assignment.backingCache);
    // True if assignment counts have been refreshed already on the page.
    private refreshed = false;

    constructor(params: any) {
        super(params);
        this._mixin(params);
    }
    override _mixin(params: any) {
        Object.assign(this, params);
        if (Is.number(params.assignee)) {
            this.assignee = Base.get(User, params.assignee);
        } else if (!(params.assignee instanceof User)) {
            this.assignee = null;
        }
        this.owner = Base.get(User, params.owner);
    }
    override defaultLastActivity() {
        return this.created;
    }
    assigneeDisplay() {
        return this.assignee ? this.assignee.display() + " [" + this.batchId + "]" : "unassigned";
    }
    override display() {
        return this.name + " [" + this.batchId + "]";
    }
    override compare(other: Assignment) {
        return this.created - other.created;
    }
    reassign(users: User.Id[], numDocs: number[], includeReviewed = false) {
        Task.createTask("reassign.rest", {
            id: this.id,
            assignees: users,
            numDocs: numDocs,
            includeReviewed,
            success: () => {
                if (this.assignee === null) {
                    // unassigned assignment is never deleted
                    const numAssigned = numDocs?.reduce((acc, num) => acc + num) ?? this.size;
                    this.updateCounts({
                        size: this.size - numAssigned,
                        numReviewed: this.numReviewed,
                    });
                } else {
                    Base.remove(this);
                }
            },
        });
    }
    selfAssign(numDocs: number) {
        Task.createTask(
            "selfAssign.rest",
            {
                id: this.id,
                numDocs: numDocs,
            },
            {
                onFinish: (task) => {
                    if (task.state === Task.COMPLETED) {
                        Base.set(Assignment, task.data);
                    }
                },
            },
        );
    }

    isRefreshed(): boolean {
        return this.refreshed;
    }

    /**
     * Refresh the counts of this assignment.
     * Accounts for per-document permissions if applicable to user.
     * Will publish count updates through {@link Base#publish} just once regardless of times
     * invoked. Do not use force == true in contexts where it could be invoked repeatedly.
     * @param force if true, remove from caches to force a request to refresh sizes
     */
    async refresh(force = false): Promise<Counts | null> {
        if (force) {
            this.refreshed = false;
            Assignment.backingCache.delete(this);
        }
        return await Assignment.countCache(this);
    }

    /**
     * Update counts and publish changes for the Assignment and corresponding Assignment Group if
     * it exists Will always publish on first invocation. This is to trigger components like cards
     * that are waiting for the first response.
     */
    updateCounts(counts: Counts) {
        const numReviewedChange = counts.numReviewed - this.numReviewed;
        const sizeChange = counts.size - this.size;
        this.numReviewed = counts.numReviewed;
        this.size = counts.size;
        if (numReviewedChange || sizeChange || !this.refreshed) {
            this.refreshed = true;
            Base.publish(this);
            // Avoid circular import by using classname to fetch assignment group
            const group = Base.get("AssignmentGroup", this.group) as AssignmentGroup;
            if (group) {
                group.numReviewed += numReviewedChange;
                group.size += sizeChange;
                if (this.assignee == null) {
                    group.assignableSize = this.size - this.numReviewed;
                }
                Base.publish(group);
            }
        }
    }
}

function getCountsById(assignments: Assignment[]): Promise<(Counts | null)[]> {
    const ids = assignments.map((b) => b.id);
    return Rest.post("assignment/counts.rest", { ids });
}

/* TODO Refactor this to remove module namespace */
/* eslint-disable-next-line @typescript-eslint/no-namespace */
module Assignment {
    export type Id = number & Base.Id<"Assignment">;

    export function selfAssign(
        assignment: Assignment,
        claimButtonAction = () => {},
    ): SelfAssignDialog {
        const selfAssignDialog = new SelfAssignDialog(assignment, claimButtonAction);
        assignment
            .refresh()
            .then((counts) =>
                selfAssignDialog.updateWithUnreviewedSize(
                    counts ? counts.size - counts.numReviewed : 0,
                ),
            )
            .catch((e: Rest.Failed) => {
                selfAssignDialog.hide();
                throw e;
            });

        selfAssignDialog.show();
        setEverId(selfAssignDialog._submitButton.node, EVERID.HOME_PAGE.CLAIM_ASSIGNMENT_BUTTON);
        Recommendations.SELF_ASSIGN.getStep(2).reregisterDisplayer({
            node: EVERID.HOME_PAGE.CLAIM_ASSIGNMENT_BUTTON,
            placement: [PopoverPlacement.BOTTOM],
            nextFunction: () => selfAssignDialog.submit(),
            nextFunctionInverse: () => {
                selfAssignDialog.hide();
            },
        });
        return selfAssignDialog;
    }

    export function showAssignmentCard(assignment: Assignment): boolean {
        // Do not show cards for unassigned assignments.
        // Unassigned assignment will be serialized if user has self-assign permissions.
        return !!assignment.assignee;
    }

    export class SelfAssignDialog extends QueryDialog {
        static INITIAL_AMOUNT = 200;
        private unreviewedSize: number;
        private numDocs: UI_Validated.ValidatedNumber;
        constructor(
            private assignment: Assignment,
            private claimButtonAction = () => {},
        ) {
            super({
                title: "Self-assign unreviewed documents",
                prompt: Dom.h3({ class: "h2" }, assignment.name),
                body: Dom.div(
                    { class: "self-assign-free-body" },
                    "Loading number of documents available to self-assign...",
                ),
                submitText: "Loading...",
            });
            this.disableSubmit();
        }
        override submit() {
            if (this.onSubmit(this)) {
                this.hide(true);
                // If there is no assignments to claim, should not scroll to the top/other action.
                this.unreviewedSize !== 0 && this.claimButtonAction();
            }
        }
        updateWithUnreviewedSize(unreviewedSize: number) {
            if (this.isOpen()) {
                this.unreviewedSize = unreviewedSize;
                this.unreviewedSize === 0 ? this.noDocumentsAvailable() : this.documentsAvailable();
            }
        }
        private noDocumentsAvailable() {
            Dom.setContent(this.body, "There are no unreviewed documents to claim.");
            this.setSubmitButton("Ok");
        }
        private documentsAvailable() {
            this.assignment.batchSize ? this.sizeSet() : this.sizeNotSet();
            this.setSubmitButton("Claim");
        }
        private sizeSet() {
            const count = Math.min(this.assignment.batchSize, this.unreviewedSize);
            Dom.setContent(
                this.body,
                Dom.span(
                    "Claim ",
                    Dom.b(Util.countOf(count, "document")),
                    " (of ",
                    Dom.b(this.unreviewedSize),
                    ") ?",
                ),
            );
            this.onSubmit = () => {
                this.assignment.selfAssign(
                    this.assignment.batchSize < this.unreviewedSize
                        ? this.assignment.batchSize
                        : this.unreviewedSize,
                );
                return true;
            };
        }
        private sizeNotSet() {
            this.buildNumDocs();
            Dom.setContent(
                this.body,
                Dom.span("Claim"),
                Dom.node(this.numDocs),
                Dom.span("of ", Dom.b(this.unreviewedSize), " unreviewed documents"),
            );
            this.onSubmit = () => {
                if (!this.numDocs.isValid()) {
                    Dialog.ok(
                        "Error",
                        `Please enter a number between 1 and ${this.unreviewedSize}.`,
                    );
                    return false;
                }
                this.assignment.selfAssign(this.numDocs.getValue() || this.unreviewedSize);
                return true;
            };
            this.numDocs.focus();
        }
        private buildNumDocs() {
            this.numDocs = new UI_Validated.ValidatedNumber({
                name: "# of docs",
                max: this.unreviewedSize,
                required: true,
                integerValued: true,
                inline: true,
            });
            // if the number of unreviewed documents is less than the set initial amount,
            // set the initial amount to the number of unreviewed documents.
            this.numDocs.setValue(Math.min(SelfAssignDialog.INITIAL_AMOUNT, this.unreviewedSize));
            Dom.addClass(this.numDocs, "num-docs-input");
            // numDocs needs to be destroyed, and will be by adding it to the toDestroy list
            this.registerDestroyable(this.numDocs);
        }
        private setSubmitButton(text: string) {
            this._submitButton.setContent(text);
            this.disableSubmit(false);
        }
    }

    export interface UserStats {
        id: User.Id;
        user: User;
        assignments: Assignment[];
        size: number;
        numReviewed: number;
    }

    /**
     * Refreshes counts of assignments assigned to the users before generating stats
     * @param users set of users to get userStats for
     */
    export async function userToStats(users: Set<User>) {
        const applicableAssignments = Base.get(Assignment).filter((a) => users.has(a.assignee));
        await Promise.all(applicableAssignments.map((a) => a.refresh()));
        return getUserStats(applicableAssignments);
    }

    // Will use `Base.get(Assignment)` if `assignments` is undefined.
    export function getUserStats(assignments?: Assignment[]) {
        const stats: { [userId: string]: UserStats } = {};
        (Is.defined(assignments) ? assignments : Base.get(Assignment)).forEach((assignment) => {
            if (assignment.assignee) {
                if (!(assignment.assignee.id in stats)) {
                    stats[assignment.assignee.id] = {
                        id: assignment.assignee.id,
                        user: assignment.assignee,
                        assignments: [],
                        size: 0,
                        numReviewed: 0,
                    };
                }
                const userEntry = stats[assignment.assignee.id];
                userEntry.assignments.push(assignment);
                userEntry.size += assignment.size;
                userEntry.numReviewed += assignment.numReviewed;
            }
        });
        return stats;
    }

    export function progressColor(progress: number): EverColor {
        if (progress < 0) {
            return ColorTokens.PROGRESS_MULTICOLOR_UNDEFINED;
        } else if (progress < 0.2) {
            return ColorTokens.PROGRESS_MULTICOLOR_LOW;
        } else if (progress < 0.4) {
            return ColorTokens.PROGRESS_MULTICOLOR_MEDIUM_LOW;
        } else if (progress < 0.6) {
            return ColorTokens.PROGRESS_MULTICOLOR_MEDIUM;
        } else if (progress < 0.9) {
            return ColorTokens.PROGRESS_MULTICOLOR_MEDIUM_HIGH;
        } else {
            return ColorTokens.PROGRESS_MULTICOLOR_HIGH;
        }
    }
}

export = Assignment;
