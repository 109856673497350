import Arr = require("Everlaw/Core/Arr");
import Base = require("Everlaw/Base");
import { ColorTokens } from "design-system";
import Database = require("Everlaw/Database");
import Entities = require("Everlaw/Entities");
import MetadataAnalyses = require("Everlaw/Upload/Metadata/MetadataAnalyses");
import MetadataDefinition = require("Everlaw/Upload/Metadata/MetadataDefinition");
import { UploadFileScanner } from "Everlaw/Upload/Util/PdfnlfUtils";
import Project = require("Everlaw/Project");
import Rest = require("Everlaw/Rest");
import UploadCsv = require("Everlaw/Upload/Util/UploadCsv");
import UploadDetails = require("Everlaw/Upload/Util/UploadDetails");
import UploadProgress = require("Everlaw/Upload/Util/UploadProgress");
import UploadState = require("Everlaw/Upload/Util/UploadState");
import User = require("Everlaw/User");
import UserObject = require("Everlaw/UserObject");
import Util = require("Everlaw/Util");
import { Color } from "Everlaw/ColorUtil";
import { MinimalOrganization } from "Everlaw/MinimalOrganization";

interface Ownership {
    id: User.Id;
    guid: string;
}

/**
 * Names of stages from which an overlay upload cannot be deleted. Legacy overlay uploads that are
 * now in the Error or Aborted stage are allowed to be deleted since they don't really represent any
 * valuable information.
 *
 * Should match Upload.java.
 */
const NO_OVERLAY_DELETE = {
    OverlayDocuments: true,
    OverlayMetadata: true,
    VerifyCompletion: true,
    ReleaseOwnership: true,
    SendToWorkers: true,
    Successful: true,
};

const DMY_DESC = "DMY";
const DMY_LONG_DESC = `${DMY_DESC} (day, month, year)`;
const MDY_DESC = "MDY";
const MDY_LONG_DESC = `${MDY_DESC} (month, day, year)`;

/**
 * A base object representing an in-progress or completed upload. It offers all the standard EBO
 * functionality. The functionality specific to uploading can be found in the Uploads folder.
 */
class Upload extends Base.Object {
    get className() {
        return "Upload";
    }

    // mixin fields

    override id: number;
    parcel: Parcel;
    name: string;
    userId: User.Id;
    authInfo: string;
    databaseId: number;
    defaultProjectId: number;
    started: number;
    completed: number;
    visible: number;
    /**
     * Corresponds to a backend UploadStage enum, and maps into UploadStages as a frontend value.
     */
    stage: string;
    private uploadFlags: number;
    private armFlags: Upload.ArmFlag;
    /**
     * The JSON data associated with the current stage. May be undefined in very old uploads
     * predating March 10, 2016.
     *
     * TODO: To prevent long-term storage of state that's only used at upload time, we intend to
     * move configuration portions of UploadState to a separate field, while the state can be
     * nulled out.
     */
    state: UploadState;
    error: string;
    missingFilesStatus: Upload.MissingFilesStatus;
    /**
     * Filled in from a search during serialization.
     */
    searchableNewDocs: number;
    searchableOverlayDocs: number;
    searchableTotalDocs: JSON;
    billableSize: number;

    ownership: Ownership;

    /**
     * If an upload stage is currently ongoing, this indicates its backend progress.
     */
    progress: UploadProgress;

    /*
     * Used for "PDF uploads without a load file" uploads where we generate load files from a list
     * of files insteadof reading a load file from disk, and calculating filename.
     */
    loadFile: string[];
    /**
     * Used for tracking information about rejected CombinationAnalyses.
     */
    private rejectedCombinationAnalyses: Set<string> = new Set();

    constructor(params: any) {
        super(params);
        this._mixin(params);
    }
    override _mixin(params: any) {
        this.name = params.name;
        this.userId = params.userId;
        this.authInfo = this.getAuthInfo(params.authorization);
        this.databaseId = params.databaseId;
        this.defaultProjectId = params.defaultProjectId;
        this.started = params.started;
        this.completed = params.completed;
        this.visible = params.visible;
        this.stage = params.stage;
        this.uploadFlags = params.uploadFlags;
        this.armFlags = params.armFlags;
        // Old uploads may have never had state, and we may someday erase the state of uploads that
        // have been complete for a while.
        if ("state" in params) {
            this.state = JSON.parse(params.state, (key, value) => {
                return UploadState.reviver(this.isPdfnlf(), key, value);
            });
        }
        this.error = params.error;
        this.missingFilesStatus = Upload.MissingFilesStatus[<string>params.missingFilesStatus];
        this.parcel = params.parcel;
        this.ownership = params.ownership;
        if (params.searchableNewDocs != null) {
            this.searchableNewDocs = params.searchableNewDocs;
        }
        if (params.searchableOverlayDocs != null) {
            this.searchableOverlayDocs = params.searchableOverlayDocs;
        }
        if (params.searchableTotalDocs != null) {
            this.searchableTotalDocs = params.searchableTotalDocs;
        }
        this.billableSize = params.billableSize;
        // PROGRESS events add progress to the upload, and they don't use Base.set. All other events
        // occur for uploads that are not currently in-progress.
        delete this.progress;
    }

    getUploadTypeName(withPrefix = true): string {
        if (this.isArmUpload()) {
            // Arm Uploads shouldn't be prepended with "Processed data upload".
            return "Relativity ARM workspace upload";
        }
        let name = withPrefix ? `Processed data upload ${Entities.BULL} ` : "";
        if (this.isPdfnlf()) {
            name += "PDFs without a load file";
        } else {
            name += "Document sets with load file";
        }
        if (this.isNew() && this.isOverlay()) {
            name += ` ${Entities.BULL} Mixed`;
        } else if (this.isOverlay()) {
            name += ` ${Entities.BULL} Overlay`;
        } else if (this.isNew()) {
            name += ` ${Entities.BULL} New`;
        }
        return name;
    }

    private getAuthInfo(auth: any) {
        if (auth) {
            const authId = auth.authorizationId;
            if (auth.authorizationType === "USER") {
                const user = Base.get(User, authId);
                if (user) {
                    return user.displayWithPrimaryOrg();
                } else {
                    return "Unknown";
                }
            } else if (auth.authorizationType === "ORGANIZATION") {
                const authOrg = Base.get(MinimalOrganization, authId);
                if (authOrg) {
                    return `(Organization) ${authOrg.display()}`;
                }
            } else if (auth.authorizationType === "OVERRIDE") {
                return `(Override) ${auth.authorizationComment}`;
            }
        }
        return null;
    }

    /**
     * Sorts uploads by descending start time, breaking ties by descending ID. This comparison must
     * match `UploadService.java#makeQuery`.
     */
    override compare(o: Upload) {
        return o.started - this.started || o.id - this.id;
    }
    override display() {
        return this.name;
    }

    /**
     * This is only defined when on the Superuser or OrgAdmin page.
     */
    database() {
        return Base.get(Database, this.databaseId);
    }

    gaName() {
        return "Processed Uploads";
    }

    getUserId() {
        return this.userId;
    }
    getCreated() {
        return this.started;
    }
    getSize() {
        return this.billableSize;
    }
    getSizeTooltip() {
        return "Billable size of all documents affected by this upload";
    }

    /**
     * These utility methods for manipulating the `uploadFlags` must remain in-sync with the
     * implementations provided by Upload.java.
     */

    /**
     * uploadFlags were migrated from 32-bit integers to 64-bit integers, and bitwise operations in
     * TypeScript discarded the top 32 bits of information. This implementation of checking is necessary
     * to apply the same bitwise logic present before without running into overflow/underflow errors.
     */
    private hasFlag(flag: Upload.Flag): boolean {
        return Util.bitwiseAND53(this.uploadFlags, flag.mask) === flag.mask;
    }
    private hasArmFlag(flag: Upload.ArmFlag) {
        return !!(this.armFlags & flag);
    }
    checkArmTables(): boolean {
        return this.hasArmFlag(Upload.ArmFlag.CHECK_ARM_TABLES);
    }
    isArmUpload(): boolean {
        return this.hasArmFlag(Upload.ArmFlag.IS_ARM_UPLOAD);
    }
    isNew() {
        return this.hasFlag(Upload.Flag.ALLOW_CREATE);
    }
    isOverlay() {
        return Upload.Flag.VALUES.some((f) => f.willOverlay && this.hasFlag(f));
    }
    /* If you are uploading Pdfs without a load file, we generated one */
    isPdfnlf() {
        return this.hasFlag(Upload.Flag.PDFNLF);
    }
    /**
     * Gets the document count for in-progress uploads factoring in what the user has selected to
     * upload in the case of a mixed upload. For completed uploads, see fetchDetails.
     */
    inProgressDocCount(includeDocsOverSizeLimit: boolean) {
        const splitSummary = this.state.loadfileSplitSummary;
        if (!splitSummary) {
            // The split summary has not been determined yet.
            return 0;
        }
        let numNew = this.isNew() ? splitSummary.numNew : 0;
        let numOverlay = this.isOverlay() ? splitSummary.numOverlay : 0;
        if (!includeDocsOverSizeLimit && this.hasUploadedDocOverSizeLimit()) {
            numNew -= this.state.exceedSize.numNew;
            numOverlay -= this.state.exceedSize.numOverlay;
        }
        return numNew + numOverlay;
    }

    isLegacyOverlay() {
        return this.uploadFlags === 0;
    }
    private setting(flag: Upload.Flag) {
        return this.isNew() || this.hasFlag(flag);
    }
    settingText() {
        return this.setting(Upload.Flag.OVERLAY_TEXT);
    }
    settingImages() {
        return this.setting(Upload.Flag.OVERLAY_IMAGES);
    }
    settingNatives() {
        return this.setting(Upload.Flag.OVERLAY_NATIVES);
    }
    settingMetadata() {
        return this.setting(Upload.Flag.OVERLAY_METADATA);
    }
    overlayingMetadata() {
        return this.hasFlag(Upload.Flag.OVERLAY_METADATA);
    }
    metadataColumns(includeWiped: boolean) {
        const colDef = this.state.columnDefinition;
        const specialCols = [
            colDef.nativePath,
            colDef.textPath,
            colDef.beginBates,
            colDef.endBates,
            colDef.pageCount,
        ];
        return colDef.ignored.filter(
            (val, i) =>
                !val
                && !Arr.contains(specialCols, i)
                && (includeWiped || !colDef.wiped || !colDef.wiped[i]),
        );
    }
    anyMetadataColumns() {
        return !!this.metadataColumns(true).length;
    }
    anyUnwipedMetadataColumns() {
        return !!this.metadataColumns(false).length;
    }
    overlayDescription() {
        const description: string[] = [];
        if (this.hasFlag(Upload.Flag.OVERLAY_TEXT)) {
            description.push("Text");
        }
        if (this.hasFlag(Upload.Flag.OVERLAY_IMAGES)) {
            description.push("Images");
        }
        if (this.hasFlag(Upload.Flag.OVERLAY_NATIVES)) {
            description.push("Natives");
        }
        if (this.hasFlag(Upload.Flag.OVERLAY_METADATA)) {
            description.push("Metadata");
        }
        if (this.hasFlag(Upload.Flag.OVERLAY_NUM_PAGES)) {
            description.push("Number of pages");
        }
        if (description.length) {
            return description.join(", ");
        }
        return "Unknown";
    }
    canDownloadLoadFile() {
        return this.hasFlag(Upload.Flag.CAN_DOWNLOAD_LOADFILE);
    }
    hasSplitInfo() {
        return this.hasFlag(Upload.Flag.HAS_SPLIT_INFO);
    }
    canDownloadArmWorkProduct(): boolean {
        return this.hasArmFlag(Upload.ArmFlag.CAN_DOWNLOAD_ARM_WORKPRODUCT);
    }
    canDownloadArmSearches(): boolean {
        return this.hasArmFlag(Upload.ArmFlag.CAN_DOWNLOAD_ARM_SEARCHES);
    }
    canDownloadArmUsers(): boolean {
        return this.hasArmFlag(Upload.ArmFlag.CAN_DOWNLOAD_ARM_USERS);
    }
    canDownloadArmDatabaseBackup(): boolean {
        return this.hasArmFlag(Upload.ArmFlag.CAN_DOWNLOAD_ARM_BACKUP);
    }
    canDownloadArmArchive(): boolean {
        return this.hasArmFlag(Upload.ArmFlag.CAN_DOWNLOAD_ARM_ARCHIVE);
    }

    canDownloadArmAlteredBackup(): boolean {
        return this.hasArmFlag(Upload.ArmFlag.CAN_DOWNLOAD_ALTERED_BACKUP);
    }

    dayMonthPreference(long = false) {
        return this.hasFlag(Upload.Flag.DMY_SORT)
            ? long
                ? DMY_LONG_DESC
                : DMY_DESC
            : long
              ? MDY_LONG_DESC
              : MDY_DESC;
    }

    otherDayMonthOption() {
        return this.hasFlag(Upload.Flag.DMY_SORT) ? MDY_DESC : DMY_DESC;
    }

    isLocked() {
        return this.ownership && !this.ownedByMe();
    }

    ownedByMe() {
        return (
            this.ownership
            && this.ownership.id === User.me.id
            && this.ownership.guid === Upload.OWNERSHIP_GUID
        );
    }
    /**
     * Returns a message describing the ownership of this upload, or the empty string if unowned.
     */
    ownershipMessage() {
        // NOTE: The messages here and in UploadService#takeOwnership must remain in-sync.
        return this.ownership
            ? this.ownership.id === User.me.id
                ? this.ownership.guid === Upload.OWNERSHIP_GUID
                    ? "You have control of this upload"
                    : "You may be working on this upload in another browser tab"
                : User.displayById(this.ownership.id) + " is working on this upload"
            : "";
    }
    takeOwnership(stage: string, override?: boolean) {
        Rest.post(`/parcel/${this.parcel}/upload/takeOwnership.rest`, {
            upload: this.id,
            projectId: Project.getCurrentId(),
            stage: stage,
            guid: Upload.OWNERSHIP_GUID,
            override: (override && this.ownership && this.ownership.guid) || null,
        });
    }
    relinquishOwnership(onUnload = false) {
        if (this.ownedByMe()) {
            const post = onUnload ? Rest.postOnPageUnload : Rest.post;
            post(`/parcel/${this.parcel}/upload/relinquishOwnership.rest`, {
                uploads: [this.id],
                projectId: Project.getCurrentId(),
                guid: Upload.OWNERSHIP_GUID,
            });
        }
    }

    canDelete() {
        return (
            this.stage !== "Delete"
            && this.stage !== "MixedOverlayDeleted"
            && (this.isNew()
                || !(this.stage in NO_OVERLAY_DELETE)
                || (this.stage === "Successful" && this.searchableOverlayDocs === 0))
        );
    }

    hasMissingFiles() {
        return this.missingFilesStatus > Upload.MissingFilesStatus.ALL_PRESENT;
    }

    hasUnresolvedMissingFiles() {
        return (
            this.hasMissingFiles()
            && this.missingFilesStatus !== Upload.MissingFilesStatus.ALL_RESOLVED
        );
    }

    hasNewFiles() {
        return this.isNew() && this.stage !== "MixedOverlayDeleted";
    }

    hasOverlayErrors() {
        return this.hasFlag(Upload.Flag.HAS_OVERLAY_ERRORS);
    }

    hasDocOverSizeLimit() {
        return this.hasFlag(Upload.Flag.EXCEEDS_SIZE_LIMIT);
    }

    /**
     * Returns true if hasDocOverSizeLimit returns true AND the upload is not a PDFNLF upload.
     * PDFNLF uploads automatically skip documents that exceed the size limit during loadfile
     * generation. There are many stages that aren't skipped if hasDocOverSizeLimit that don't
     * actually need to be run for PDFNLF uploads, which is why this helper function exists.
     */
    hasUploadedDocOverSizeLimit() {
        return this.hasDocOverSizeLimit() && !this.isPdfnlf();
    }

    hasLoadfileErrors(): boolean {
        return (
            this.hasSkippableLoadfileErrors()
            || (this.state
                && (this.state.optRequiredPageCountConflict
                    || this.state.optRequiredUnknownPageRange))
        );
    }

    hasSkippableLoadfileErrors(): boolean {
        return (
            this.hasFlag(Upload.Flag.HAS_PARSING_ERRORS)
            || this.hasFlag(Upload.Flag.HAS_INTERPRETATION_ERRORS)
        );
    }

    hasInternalBatesOverlap() {
        return this.hasFlag(Upload.Flag.HAS_INTERNAL_OVERLAP);
    }

    hasNonPDFErrors() {
        return this.hasFlag(Upload.Flag.HAS_NON_PDF_ERRORS);
    }

    hasPageCountErrors() {
        return this.hasFlag(Upload.Flag.HAS_PAGE_COUNT_ERRORS);
    }

    hasFileTooBigErrors() {
        return this.hasFlag(Upload.Flag.HAS_FILE_TOO_BIG_ERRORS);
    }

    hasDuplicateErrors() {
        return this.hasFlag(Upload.Flag.HAS_DUPLICATE_ERRORS);
    }

    hasFileReadErrors() {
        return this.hasFlag(Upload.Flag.HAS_FILE_READ_ERRORS);
    }

    hasUnknownPDFNLFErrors() {
        return this.hasFlag(Upload.Flag.HAS_UNKNOWN_PDFNLF_ERRORS);
    }

    hasPDFNLFOverlayErrors() {
        return this.hasFlag(Upload.Flag.HAS_PDFNLF_OVERLAY_ERRORS);
    }

    hasPDFNLFErrors() {
        return (
            this.isPdfnlf()
            && (this.hasNonPDFErrors()
                || this.hasPageCountErrors()
                || this.hasFileTooBigErrors()
                || this.hasDuplicateErrors()
                || this.hasFileReadErrors()
                || this.hasUnknownPDFNLFErrors()
                || this.hasPDFNLFOverlayErrors())
        );
    }

    rename(newName: string): Promise<string> {
        if (this.progress) {
            return Promise.reject(
                new Rest.Failed(
                    null,
                    "The upload is currently in progress. Please rename when it is complete.",
                    0,
                    true,
                ),
            );
        } else if (newName !== this.name) {
            // We rely on the "MODIFY" event to propagate the resulting change.
            return Rest.post(`/parcel/${this.parcel}/upload/rename.rest`, {
                id: this.id,
                projectId: Project.getCurrentId(),
                newName,
            }).then((newName) => {
                this.name = newName;
                Base.publish(this);
                return newName;
            });
        } else {
            return Promise.resolve(this.name);
        }
    }

    /**
     * Deletes the upload. Accepts an expected stage so that callers can be robust against
     * changes to this upload while a confirmation dialog is open. If overrideOwnership is true,
     * the upload will be deleted regardless of the current owner.
     */
    remove(stage: string, overrideOwnership?: boolean, reason?: string) {
        Rest.post(`/parcel/${this.parcel}/upload/delete.rest`, {
            upload: this.id,
            projectId: Project.getCurrentId(),
            stage: stage,
            guid: Upload.OWNERSHIP_GUID,
            override: overrideOwnership ? this.ownership.guid : null,
            reason: reason,
        });
    }
    /**
     * Aborts the current upload operation. Accepts an expected stage for the same reason
     * as remove (likewise for overrideOwnership). The presence of a `WebSocketRetryTracker`
     * indicates that we're aborting automatically because of a WebSocket error.
     */
    abort(
        stage: string,
        overrideOwnership = false,
        wsRetryTracker?: UploadState.WebSocketRetryTracker,
    ) {
        return Rest.post(`/parcel/${this.parcel}/upload/abort.rest`, {
            upload: this.id,
            projectId: Project.getCurrentId(),
            stage: stage,
            guid: Upload.OWNERSHIP_GUID,
            wsRetryTracker: JSON.stringify(wsRetryTracker),
            override: overrideOwnership ? this.ownership.guid : null,
        });
    }
    retry(newStage: string, args?: any) {
        Rest.post(`/parcel/${this.parcel}/upload/retry.rest`, {
            upload: this.id,
            projectId: Project.getCurrentId(),
            stage: this.stage,
            guid: Upload.OWNERSHIP_GUID,
            retryStage: newStage,
            args: JSON.stringify(args),
        }).then(() => (this.error = null));
    }

    changeRootFolder(name: string) {
        Rest.post(`/parcel/${this.parcel}/upload/changeRootFolder.rest`, {
            upload: this.id,
            projectId: Project.getCurrentId(),
            stage: this.stage,
            guid: Upload.OWNERSHIP_GUID,
            rootFolder: name,
        });
    }

    getMetadataDefinition() {
        const m = this.state.metadata;
        const analyses = new MetadataAnalyses(m.analyses, this.rejectedCombinationAnalyses);
        return new MetadataDefinition(m.definition, this, analyses);
    }

    getAllIgnoredHeadersSorted(): string[] {
        const metadataDefinition = this.getMetadataDefinition();
        const metadataIgnored = metadataDefinition.ignored;
        // Populate ignored headers section. Includes headers ignored in DefineSpecialColumns
        // and in the metadata analysis stages. Empty if no headers were ignored.
        const ignoredHeaders: string[] = [];
        for (const ignoredField in metadataIgnored) {
            ignoredHeaders.push(ignoredField);
        }
        const columns = this.state.columns;
        const ignoredCols = this.state.columnDefinition.ignored;
        columns.map((col, index) => {
            if (ignoredCols[index]) {
                ignoredHeaders.push(col.name);
            }
        });
        ignoredHeaders.sort();
        return ignoredHeaders;
    }

    getNewPrefixes(prefixes = this.state.batesPrefixes): string[] {
        return prefixes.filter(
            (p) => this.state.existingBatesPrefixes.indexOf(p.toUpperCase()) < 0,
        );
    }

    /**
     * Returns a Promise that resolves the start of the requested CSV file.
     */
    fetchCsvSnippet(endpoint: string) {
        return new Promise<UploadCsv.Snippet>((resolve, reject) => {
            Rest.get(`/parcel/${this.parcel}/upload/${endpoint}`, {
                upload: this.id,
                projectId: Project.getCurrentId(),
                stage: this.stage,
            }).then(resolve, reject);
        });
    }

    fetchDetails(withMissingFiles = false) {
        return new Promise<UploadDetails>((resolve, reject) => {
            Rest.get(`/parcel/${this.parcel}/upload/details.rest`, {
                upload: this.id,
                projectId: Project.getCurrentId(),
                withMissingFiles: withMissingFiles,
            }).then(resolve, (err: Rest.Failed) => {
                // The most common error in fetching details is that the upload no longer
                // exists. If that happens, and we have already deregistered the upload on the
                // frontend, then we don't need to report an error.
                if (Base.get(Upload, this.id) === this) {
                    reject(err.message);
                }
            });
        });
    }

    isSecondary() {
        return this.visible > Upload.VISIBLE_CONSTANTS.NON_VISIBLE;
    }

    getPrimaryUploadId() {
        return this.isSecondary() ? this.visible : this.id;
    }

    /*
     * Generated uploads don't read files directly from the disk they generate a load file from a list
     * of files.
     */
    setUploadFiles(files: UploadFileScanner) {
        this.state.uploadFileList = files.uploadFileList;
    }

    getUploadFiles(): UploadState.UploadFileList {
        return this.state.uploadFileList;
    }

    setLoadFile(loadFile: string[]) {
        this.loadFile = loadFile;
    }

    addRejectedAnalysisId(id: string) {
        this.rejectedCombinationAnalyses.add(id);
    }
}

/* TODO Refactor this to remove module namespace */
/* eslint-disable-next-line @typescript-eslint/no-namespace */
module Upload {
    export const CSV_ERROR = "See CSV.";
    export const OWNERSHIP_GUID = Util.getGUID();
    export const COLOR = Color.fromEverColor(ColorTokens.OBJECT_UPLOAD_PROCESSED);

    /// NOTE: These enums must match the ones in BaseUpload.java.

    export class Flag {
        mask: number;
        static ordinal = 0;
        constructor(public willOverlay: boolean) {
            this.mask = 2 ** Flag.ordinal;
            Flag.ordinal++;
            Flag.VALUES.push(this);
        }
        static VALUES: Flag[] = [];

        // These are an enum. Order matters.
        static ALLOW_CREATE = new Flag(false);
        static OVERLAY_TEXT = new Flag(true);
        static OVERLAY_IMAGES = new Flag(true);
        static OVERLAY_NATIVES = new Flag(true);
        static OVERLAY_METADATA = new Flag(true);
        static OVERLAY_NUM_PAGES = new Flag(true);
        static CLEAR_EXISTING_METADATA = new Flag(true);
        static RAW_METADATA = new Flag(true); // Legacy, unused.
        static ALLOW_NEW_PREFIXES = new Flag(false);
        static CAN_DOWNLOAD_LOADFILE = new Flag(false);
        static HAS_SPLIT_INFO = new Flag(false);
        static DMY_SORT = new Flag(false);
        static HAS_PARSING_ERRORS = new Flag(false);
        static HAS_INTERPRETATION_ERRORS = new Flag(false);
        static EXCEEDS_SIZE_LIMIT = new Flag(false);
        static PDFNLF = new Flag(false);
        static HAS_OVERLAY_ERRORS = new Flag(false);
        static HAS_INTERNAL_OVERLAP = new Flag(false);
        static HAS_EXTERNAL_OVERLAP = new Flag(false);
        static HAS_NON_PDF_ERRORS = new Flag(false);
        static HAS_PAGE_COUNT_ERRORS = new Flag(false);
        static HAS_FILE_TOO_BIG_ERRORS = new Flag(false);
        static HAS_DUPLICATE_ERRORS = new Flag(false);
        static HAS_FILE_READ_ERRORS = new Flag(false);
        static HAS_UNKNOWN_PDFNLF_ERRORS = new Flag(false);
        static HAS_PDFNLF_OVERLAY_ERRORS = new Flag(false);
        static _UNUSED_26 = new Flag(false);
        static _UNUSED_27 = new Flag(false);
        static _UNUSED_28 = new Flag(false);
        static RENAME_ALL_CUSTODIANS = new Flag(true);
        static IGNORE_ALL_CUSTODIANS = new Flag(true);
    }

    export const enum ArmFlag {
        IS_ARM_UPLOAD = 1 << 0,
        CAN_DOWNLOAD_ARM_BACKUP = 1 << 1,
        CAN_DOWNLOAD_ARM_WORKPRODUCT = 1 << 2,
        CHECK_ARM_TABLES = 1 << 3,
        DUPLICATE_ARM_NATIVES = 1 << 4,
        ARM_DATABASE_RESTORED = 1 << 5,
        ARM_BACKUP_ERROR = 1 << 6,
        ARM_MALFORMED_BATES = 1 << 7,
        ARM_PAGELESS_BATES = 1 << 8,
        ARM_FILE_MAP = 1 << 9,
        CAN_DOWNLOAD_ARM_SEARCHES = 1 << 10,
        _UNUSED_11 = 1 << 11,
        ARM_TEMPLATE_CSV = 1 << 12,
        ARM_FIELDS_REPORT = 1 << 13,
        ARM_BROKEN_FIELDS = 1 << 14,
        CAN_DOWNLOAD_ARM_ARCHIVE = 1 << 15,
        CAN_DOWNLOAD_ARM_USERS = 1 << 16,
        RENUMBER_LOADFILE = 1 << 17,
        ID_MAP_GENERATED = 1 << 18,
        ARM_PAGELESS_IDS = 1 << 19,
        ARM_NATIVE_ERRORS = 1 << 20,
        CAN_DOWNLOAD_ALTERED_BACKUP = 1 << 21,
    }

    export enum MissingFilesStatus {
        UNKNOWN,
        ALL_PRESENT,
        UNAPPROVED,
        APPROVED,
        ALL_RESOLVED,
    }

    /**
     * The kind of information pertinent to an upload for the superuser or org admin uploads page
     * is very different from the kind of information that should be present for homepage upload
     * cards. This class is the frontend upload representation for homepage cards. For the full
     * upload object, see class Upload at the top of this file.
     * This class corresponds roughly to the backend UploadSummary class, whose JSON is used to
     * Base.set the instances of this class.
     */
    export class Homepage extends UserObject {
        get className() {
            return "HomepageUpload";
        }
        override id: number;
        name: string;
        userId: number;
        created: number;
        isOverlay: boolean;
        size: number;
        constructor(params: any) {
            super(params);
            this._mixin(params);
        }
        override _mixin(params: any) {
            Object.assign(this, params);
        }
        override defaultLastActivity() {
            return this.created;
        }
        getUser() {
            return Base.get(User, this.userId);
        }
        override display() {
            return this.name;
        }
        /**
         * Appears in Processing.Dataset, Upload.Homepage, and Production. Used to display a document
         * set object with its type in search, results, review, and datavis.
         */
        displayWithType() {
            return "Processed: " + this.display();
        }
        getColor() {
            return COLOR;
        }
        override compare(other: Homepage) {
            return this.name.localeCompare(other.name);
        }
        getCreated() {
            return this.created;
        }
    }

    /**
     * Upload.visible is used to determine the visibility a particular upload on the homepage.
     * -1: Visible
     * 0: Non-Visible
     * >= 1: An id of another upload. That means this upload is secondary and documents in this
     *      upload will will show up in searches for searches for the primary upload. This upload
     *      will also not be visible independently on the homepage.
     */
    export enum VISIBLE_CONSTANTS {
        VISIBLE = -1,
        NON_VISIBLE = 0,
    }
}

export = Upload;
