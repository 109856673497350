import Arr = require("Everlaw/Core/Arr");
import ColumnCombination = require("Everlaw/Upload/Metadata/ColumnCombination");
import CombinationAnalysis = require("Everlaw/Upload/Metadata/CombinationAnalysis");
import LoadfileColumn = require("Everlaw/Upload/Metadata/LoadfileColumn");
import MergeGroup = require("Everlaw/Upload/Metadata/MergeGroup");
import MetadataAnalyses = require("Everlaw/Upload/Metadata/MetadataAnalyses");
import NameStatus = require("Everlaw/Upload/Metadata/NameStatus");
import TypeFormat = require("Everlaw/Upload/Metadata/TypeFormat");
import Upload = require("Everlaw/Upload");

/**
 * Corresponds to MetadataDefinition.java.
 *
 * Note: sub elements of the definition should not use the definition during initialization
 * since it will not be fully initialized until all sub elements are. Any usages of the definition
 * should be deferred until after initialization.
 */
class MetadataDefinition {
    version: number;
    /**
     * This method is called after any change that might affect the interpretation of metadata
     * values. It's intended to be overridden by users.
     */
    updateValidity() {}
    mergeGroupByName: { [targetName: string]: MergeGroup } = {};
    mergeGroups: MergeGroup[] = [];
    columnCombinations: ColumnCombination[] = [];
    columns: LoadfileColumn[] = [];
    ignored: { [header: string]: LoadfileColumn } = {};
    dateTimeFormatWhitelist: string[];
    private _onColumnCombinationChange: MetadataDefinition.ColumnCombinationChangeHandler;
    constructor(
        params: any,
        public upload: Upload,
        readonly analyses: MetadataAnalyses,
    ) {
        params = params || {}; // There is no existing backend MetadataDefinition on first construction.
        this.version = params.version || 0;
        if (params.mergeGroups) {
            params.mergeGroups.forEach((mgJson) => {
                const mg = new MergeGroup(mgJson, this);
                this.addMergeGroup(mg);
                mg.columnCombinations.forEach((columnCombination) => {
                    this.addColumnCombination(columnCombination);
                    columnCombination.columns.forEach((col) => this.addColumn(col));
                });
            });
            if (params.ignored) {
                params.ignored.forEach((colJson) => {
                    const c = new LoadfileColumn(colJson, this);
                    this.addColumn(c);
                    this.ignored[c.header] = c;
                });
            }
        }
        const shouldHaveHeaders = this.analyses.getColumnHeaders();
        const alreadyHaveHeaders = this.columns.map((c) => c.header);
        for (const header of alreadyHaveHeaders) {
            if (shouldHaveHeaders.indexOf(header) < 0) {
                new Error("Have a header we shouldn't");
            }
        }
        for (const header of shouldHaveHeaders) {
            if (alreadyHaveHeaders.indexOf(header) < 0) {
                this.addColumn(new LoadfileColumn({ header }, this));
            }
        }
        this.unfloat();
    }
    toJSON() {
        const floatingColumns = this.columns.filter((c) => !c.columnCombination && !c.ignored());
        const floatingCombinations = this.columnCombinations.filter((cc) => !cc.mergeGroup);
        if (floatingColumns.length > 0 || floatingCombinations.length > 0) {
            throw new Error(
                "Trying to serialize a metadata definition with floating"
                    + " LoadfileColumns or ColumnCombinations",
            );
        }
        return {
            version: this.version,
            mergeGroups: this.mergeGroups,
            dateTimeFormatWhitelist: this.dateTimeFormatWhitelist,
            ignored: Object.values(this.ignored),
        };
    }
    getNumDocs() {
        return this.analyses.numDocs;
    }
    getNameRestrictions(name: string) {
        return this.analyses.getNameRestrictions(name);
    }
    isOverlay() {
        return this.upload.isOverlay();
    }
    isNew() {
        return this.upload.isNew();
    }
    private addColumn(col: LoadfileColumn) {
        this.columns.push(col);
    }
    private addColumnCombination(cc: ColumnCombination) {
        this.columnCombinations.push(cc);
    }
    private removeColumnCombination(cc: ColumnCombination) {
        this.columnCombinations.splice(this.columnCombinations.indexOf(cc), 1);
        cc.destroy();
    }
    private addMergeGroup(mg: MergeGroup) {
        this.mergeGroups.push(mg);
        this.mergeGroupByName[mg.targetName] = mg;
    }
    private removeMergeGroup(mg: MergeGroup) {
        this.mergeGroups.splice(this.mergeGroups.indexOf(mg), 1);
        delete this.mergeGroupByName[mg.targetName];
        mg.destroy();
    }

    comboOptions(col: LoadfileColumn) {
        return Arr.filterNonNullish(
            this.columns
                .filter((other) => other.canCombine())
                .map((other) =>
                    this.analyses.combinationAnalysis(
                        col.selectedTypeFormat,
                        other.selectedTypeFormat,
                    ),
                ),
        );
    }
    comboTypeCombinations() {
        return this.columnCombinations.filter((cc) => cc.isComboType());
    }
    /**
     * Float the given column from its combined column, cascading float operations as necessary.
     * "Floating" means that the entity has no parent, but is still part of the metadata definition.
     * Detaching an entity from its parent in this way means the parent should be deleted and removed
     * from the definition if it has no children.
     */
    private floatColumn(column: LoadfileColumn) {
        const cc = column.columnCombination;
        if (cc) {
            this.floatColumnCombination(cc);
            // Detach the columns from the combined column.
            cc.columns.forEach((col) => (col.columnCombination = null));
            this.removeColumnCombination(cc);
        }
    }
    private floatColumnCombination(columnCombination: ColumnCombination) {
        const mg = columnCombination.mergeGroup;
        if (mg) {
            columnCombination.mergeGroup = null;
            if (mg.columnCombinations.length > 1) {
                mg.remove(columnCombination);
            } else {
                this.removeMergeGroup(mg);
            }
        }
    }
    /**
     * Combine all "floating" LoadfileColumns and merge all "floating" ColumnCombinations,
     * presenting a complete definition in which each column is represented in some MergeGroup.
     * Returns a list of ColumnCombinations that were destroyed in this process.
     */
    private unfloat() {
        const destroyedCombinations: ColumnCombination[] = [];
        // Combine any columns we can first, without specific ordering.
        this.columns.forEach((c, ci) => {
            if (c.canCombine()) {
                Arr.first(this.columns, (o, oi) => {
                    if (oi <= ci || !o.canCombine()) {
                        return false;
                    }
                    const ca = this.analyses.combinationAnalysis(
                        c.selectedTypeFormat,
                        o.selectedTypeFormat,
                    );
                    if (ca && ca.compatibleNames && !ca.rejected) {
                        // Save the old single-column combined columns as 'destroyed'.
                        c.columnCombination && destroyedCombinations.push(c.columnCombination);
                        o.columnCombination && destroyedCombinations.push(o.columnCombination);
                        // Combine these two columns.
                        this.createColumnCombination([c, o]);
                        return true;
                    }
                    return false;
                });
            }
        });
        // Any columns still floating get their own combined column.
        this.columns
            .filter((c) => !c.columnCombination && !c.ignored())
            .forEach((c) => this.createColumnCombination([c]));
        return Arr.unique(destroyedCombinations);
    }
    private createColumnCombination(cols: LoadfileColumn[]) {
        cols.forEach((col) => this.floatColumn(col));
        const combination = new ColumnCombination(cols, this, null);
        this.columnCombinations.push(combination);
        // Merge the combined column into a merge group.
        const names = combination.orderedNameOptions();
        const addedValid = names.some((name) => this.mergeColumnCombination(name, combination));
        if (!addedValid) {
            // Force merge this combined column into its top choice merge group.
            this.mergeColumnCombination(names[0], combination, true);
        }
        return combination;
    }
    /**
     * Merge given ColumnCombination with the MergeGroup of the given name. Return true if the merge
     * was successful, otherwise false. If allowInvalid is true, do the merge even if name-type
     * constraints will be violated. The given ColumnCombination must be "floating".
     */
    private mergeColumnCombination(
        name: string,
        combination: ColumnCombination,
        allowInvalid = false,
    ) {
        const nameStatus = NameStatus.of(combination.type(), this.getNameRestrictions(name));
        if (!allowInvalid && !nameStatus.valid) {
            return false;
        }
        const existing = this.mergeGroupByName[name];
        if (existing) {
            return existing.add(combination, allowInvalid);
        }
        this.addMergeGroup(
            new MergeGroup({ targetName: name, columnCombinations: [combination] }, this),
        );
        return true;
    }
    typeFormatAction(column: LoadfileColumn, typeFormat: TypeFormat, action: () => void) {
        const isCurrent = typeFormat.equals(column.selectedTypeFormat);
        if (isCurrent) {
            this.floatColumn(column);
            column.selectedTypeFormat = null;
        }
        action();
        column.updatePane();
        if (isCurrent) {
            column.selectBestTypeFormat(); // type display will also be updated
            this.unfloat();
        } else {
            column.updateTypeDisplay();
        }
    }

    /* Type stage */

    ignoreColumn(column: LoadfileColumn, ignore: boolean) {
        if (ignore) {
            if (column.ignored()) {
                throw new Error("Trying to ignore an ignored column");
            }
            this.floatColumn(column);
            this.ignored[column.header] = column;
        } else {
            if (!column.ignored()) {
                throw new Error("Trying to include a non-ignored column");
            }
            delete this.ignored[column.header];
        }
        column.updateTab();
        this.unfloat();
        this.updateValidity();
    }
    changeTypeFormat(column: LoadfileColumn, newTypeFormatId: string) {
        column.selectTypeFormat(newTypeFormatId);
        this.floatColumn(column);
        this.unfloat();
        this.updateValidity();
    }

    /* Combine stage */
    combine(combinationAnalysis: CombinationAnalysis) {
        const columns = combinationAnalysis.headers.map((h) =>
            Arr.firstElement(this.columns, (c) => h === c.header),
        );
        if (columns.some((c) => c.isCombined())) {
            throw new Error("Attempting to combine an already combined column");
        }
        const oldGroups = columns.map((c) => c.columnCombination);
        columns.forEach((c) => this.floatColumn(c));
        const combination = this.createColumnCombination(columns);
        if (this._onColumnCombinationChange) {
            this._onColumnCombinationChange(oldGroups, [combination]);
        }
        this.updateValidity();
    }
    split(cc: ColumnCombination) {
        cc.combinationAnalysis().markRejected();
        this.upload.addRejectedAnalysisId(cc.combinationAnalysisId());
        const columns = cc.columns;
        columns.forEach((c) => this.floatColumn(c));
        const oldGroups = this.unfloat();
        if (oldGroups.indexOf(cc) < 0) {
            oldGroups.push(cc);
        }
        if (this._onColumnCombinationChange) {
            this._onColumnCombinationChange(
                oldGroups,
                Arr.unique(columns.map((c) => c.columnCombination)),
            );
        }
        this.updateValidity();
    }
    // Note: currently only allow one registered callback since that's all that's needed.
    registerOnColumnCombinationChange(onChange: MetadataDefinition.ColumnCombinationChangeHandler) {
        this._onColumnCombinationChange = onChange;
    }

    /* Normalize stage */

    changeMergeGroup(newName: string, cc: ColumnCombination) {
        this.floatColumnCombination(cc);
        // Allow invalid combinations when the user explicitly changes the name.
        this.mergeColumnCombination(newName, cc, true);
        this.updateValidity();
    }

    /* ApproveMetadataDefinition stage */

    getFieldsWithConflicts() {
        return this.mergeGroups.filter((mg) => mg.hasConflicts());
    }
    /**
     * Returns merge groups involving columns that were previously mapped to a different field than
     * the merge group is targeting. Only relevant for overlay uploads.
     */
    getFieldsWithUnusualMappings() {
        return this.mergeGroups.filter(
            (mg) => mg.analysis() && mg.differentPreviousMappingColumns().length > 0,
        );
    }

    destroy() {
        this.mergeGroups.forEach((mg) => mg.destroy());
        this.columnCombinations.forEach((cc) => cc.destroy());
        this.columns.forEach((c) => c.destroy());
    }
}

/* TODO Refactor this to remove module namespace */
/* eslint-disable-next-line @typescript-eslint/no-namespace */
module MetadataDefinition {
    export interface Processing {
        end(): void;
    }

    export interface ColumnCombinationChangeHandler {
        (
            oldColumnCombinations: ColumnCombination[],
            newColumnCombinations: ColumnCombination[],
        ): void;
    }
}

export = MetadataDefinition;
