import BaseSelector = require("Everlaw/UI/BaseSelector");
import Dom = require("Everlaw/Dom");
import Input = require("Everlaw/Input");
import Is = require("Everlaw/Core/Is");
import Tooltip = require("Everlaw/UI/Tooltip");
import UI = require("Everlaw/UI");
import { getFocusDiv, makeFocusable } from "Everlaw/UI/FocusDiv";

/**
 * Standard circle/dot radio selector.
 * (We used to use dijit.form.RadioButtons but they behaved poorly.)
 */
class BasicRadio extends BaseSelector<BasicRadio.Element> {
    elements: BasicRadio.Element[];
    onChange(changedTo: BasicRadio.Element, changedFrom: BasicRadio.Element) {}
    // called on any click, even if not a change
    onSelect(changedTo: BasicRadio.Element, changedFrom: BasicRadio.Element) {}
    protected override _onBlur() {
        // if we really blurred, figure out which element is now selected
        this.radios.forEach((r, i) => {
            if (r.checked) {
                this.select(this.elements[i], true);
            }
        });
        this.onBlur();
    }
    private _labels: HTMLLabelElement[];
    radios: HTMLInputElement[];
    private disabledTooltips: { [idx: string]: Tooltip } = {};
    private tooltips: { [idx: string]: Tooltip } = {};
    constructor(
        elements: (string | BasicRadio.Element)[],
        separateLines = true,
        centeredVertClass: string = null,
    ) {
        super(Dom.div({ class: "radio" }));
        const radioId = "basicradio" + this.id;
        // handle different types of elements, eg string instead of hash
        this.elements = elements.map((e) => {
            const elem: BasicRadio.Element = typeof e === "string" ? { id: e, display: e } : e;
            if (!Is.defined(elem.display)) {
                elem.display = elem.id;
            }
            return elem;
        });
        this._labels = [];
        this.radios = this.elements.map((elem, idx) => {
            const inputId = radioId + "_" + idx;
            const inputElem = Dom.create(
                "input",
                {
                    type: "radio",
                    class: "action",
                    name: radioId,
                    value: elem.id,
                    id: inputId,
                    tabindex: -1,
                },
                this.node,
            );
            const desc = Dom.create(
                "label",
                { content: this.display(elem), for: inputId },
                this.node,
            );
            if (elem.bottomMargin) {
                Dom.style(desc, "marginBottom", elem.bottomMargin);
            }
            if (elem.verticalAlign) {
                Dom.style(desc, "verticalAlign", elem.verticalAlign);
            }
            if (elem.width) {
                Dom.style(desc, "width", elem.width);
            }
            this._labels.push(desc);
            if (!!centeredVertClass) {
                Dom.addClass(desc, centeredVertClass);
            }
            if (elem.tooltip) {
                this.tooltips[idx] = new Tooltip(desc, elem.tooltip, elem.tooltipPosition);
                this.registerDestroyable(this.tooltips[idx]);
            }
            if (elem.disabledTooltip) {
                this.disabledTooltips[idx] = new Tooltip(
                    desc,
                    elem.disabledTooltip,
                    elem.tooltipPosition,
                );
                this.disabledTooltips[idx].disabled = true;
                this.registerDestroyable(this.disabledTooltips[idx]);
            }
            if (separateLines) {
                if (elem.subcontent) {
                    Dom.create(
                        "div",
                        {
                            class: "radio-subcontent",
                            content: elem.subcontent,
                        },
                        this.node,
                    );
                } else {
                    Dom.place(Dom.br(), this.node);
                }
            } else {
                if (idx > 0) {
                    Dom.style(desc, "marginLeft", elem.leftMargin ? elem.leftMargin : "24px");
                    if (elem.leftMargin) {
                        Dom.style(inputElem, "marginLeft", elem.leftMargin);
                    }
                }
            }
            // onmousedown instead of onclick because the latter actually triggers
            // the blur first, and does NOT set the focus on the new element in
            // Chrome. Thus, onBlur is invoked before the click happens. So
            // instead we hijack the action as soon as the mouse button is
            // depressed.
            const sel = () => {
                if (!inputElem.hasAttribute("disabled")) {
                    this.select(elem, true);
                }
            };
            this.connect(inputElem, Input.press, sel);
            // also trap Enter from the keyboard
            UI.onSubmit(inputElem, sel);
            this.connect(desc, Input.tap, sel);
            const focusDiv = makeFocusable(desc, "focus-label-style", elem.focusDivPos);
            this.registerDestroyable(focusDiv);
            this.registerDestroyable(
                Input.fireCallbackOnKey(focusDiv.node, [Input.ENTER, Input.SPACE], (e) => {
                    e.stopPropagation();
                    if (e.key === Input.ENTER) {
                        return;
                    }
                    e.preventDefault();
                    sel();
                }),
            );
            return inputElem;
        });
    }
    getInputNode(elem: string | BasicRadio.Element) {
        const idx = this._getIndex(elem);
        return idx < 0 ? null : this.radios[idx];
    }
    getInputLabel(elem: string | BasicRadio.Element) {
        const idx = this._getIndex(elem);
        return idx < 0 ? null : this._labels[idx];
    }
    setInputLabel(elem: string | BasicRadio.Element, content: Dom.Content) {
        const idx = this._getIndex(elem);
        if (idx >= 0) {
            Dom.setContent(this._labels[idx], content);
            !!getFocusDiv(this._labels[idx]) && getFocusDiv(this._labels[idx]).replace();
        }
    }
    getSelectedId() {
        return this._selected && this._selected.id;
    }
    override display(elem: BasicRadio.Element) {
        const disp = elem.display;
        return Is.func(disp) ? (<() => Dom.Content>disp).call(elem) : disp;
    }
    override focus() {
        if (this._selected) {
            this.radios[this.elements.indexOf(this._selected)].focus();
        } else if (this.radios.length > 0) {
            this.radios[0].focus();
        }
    }
    clear() {
        this._selected = null;
        this.radios.forEach((r) => (r.checked = false));
    }
    select(elem: string | BasicRadio.Element, noisy = false): void {
        const old = this._selected;
        const idx = this._getIndex(elem);
        if (idx < 0) {
            return;
        }
        this.radios.forEach((r, ri) => {
            r.checked = ri === idx;
        });
        this._selected = this.elements[idx];
        this._selected.onSelect && this._selected.onSelect(old);
        this.onSelect(this._selected, old);
        if (this._selected !== old && noisy) {
            this._selected.onChange && this._selected.onChange(old);
            this.onChange(this._selected, old);
        }
    }
    // elem can be a string with the id of the desired object, or the object itself.
    // returns -1 if the element is not found
    private _getIndex(elem: string | BasicRadio.Element) {
        const id = typeof elem === "string" ? elem : elem.id;
        for (let i = 0; i < this.elements.length; i++) {
            if (id === this.elements[i].id) {
                return i;
            }
        }
        return -1;
    }
    override getValue(): BasicRadio.Element {
        return <BasicRadio.Element>super.getValue();
    }
    setDisabled(elem: string | BasicRadio.Element, state: boolean): void {
        const idx = this._getIndex(elem);
        if (idx >= 0) {
            UI.toggleDisabled(this.radios[idx], state);
        }
        if (this.disabledTooltips[idx]) {
            this.disabledTooltips[idx].disabled = !state;
            if (this.tooltips[idx]) {
                this.tooltips[idx].disabled = state;
            }
        }
    }

    setAllDisabled(state: boolean): void {
        this.radios.forEach((radio) => UI.toggleDisabled(radio, state));
    }
}

module BasicRadio {
    export interface Element {
        id: string;
        display?: Dom.Content | (() => Dom.Content);
        tooltip?: string;
        disabledTooltip?: string | HTMLElement;
        value?: number;
        // Extra content to put below a radio option.  Requires separateLines=true (in the BasicRadio constructor)
        subcontent?: Dom.Content;
        tooltipPosition?: string[];
        leftMargin?: string;
        bottomMargin?: string;
        verticalAlign?: string;
        width?: string;
        // Called when a user selects this element and it was NOT previously selected
        onChange?: (e: Element) => void;
        // Called when a user clicks this element REGARDLESS of whether or not it was previously selected
        // If the element was not previously selected, onChange will be called as well
        onSelect?: (e: Element) => void;
        focusDivPos?: string;
    }

    export class RadioPlus<T extends Element> extends BasicRadio {
        constructor(elements: T[], separateLines = true, centeredVertClass: string = null) {
            super(elements, separateLines, centeredVertClass);
        }
        override getValue(): T {
            return <T>super.getValue();
        }
    }
}

export = BasicRadio;
