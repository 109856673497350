import Autocodable = require("Everlaw/Autocodable");
import AutoCodeRule = require("Everlaw/AutoCodeRule"); // Circular, types only
import Base = require("Everlaw/Base");
import Code = require("Everlaw/Code");
import CodeOrder = require("Everlaw/CodeOrder");
import { colorForId } from "Everlaw/ColorUtil";
import DeleteEntityConfirmation = require("Everlaw/SettingsPage/DeleteEntityConfirmation");
import Dom = require("Everlaw/Dom");
import Project = require("Everlaw/Project");
import Rest = require("Everlaw/Rest");
import Str = require("Everlaw/Core/Str");
import UserObject = require("Everlaw/UserObject");

class Category extends UserObject implements Autocodable {
    get className() {
        return "Category";
    }
    override id: Category.Id;
    name: string;
    projectId: Project.Id;
    mutuallyExclusive: boolean;
    description: string;
    codes: Code[] = [];
    constructor(params: any) {
        super(params);
        this._mixin(params);
    }
    override _mixin(params: any) {
        Object.assign(this, params);
        this.codes = Base.set(Code, this.codes);
    }
    getColor() {
        return colorForId(this.id, 50);
    }
    aiCodingConfigured() {
        return this.description && this.description.length > 0;
    }
    setMutuallyExclusive(state: boolean) {
        return Rest.post("categories/setMutuallyExclusive.rest", {
            id: this.id,
            mutuallyExclusive: state,
        }).then((data) => {
            return Base.set(Category, data);
        });
    }
    override display() {
        return this.name;
    }
    rename(newName: string) {
        return Rest.post("categories/rename.rest", {
            id: this.id,
            name: newName,
        }).then((data: { categoryOrder: CodeOrder; category: Category }) => {
            Base.set(CodeOrder, data.categoryOrder);
            return Base.set(Category, data.category);
        });
    }
    remove() {
        const performDelete = () => {
            return Rest.post("categories/delete.rest", { id: this.id }).then(() => {
                this._remove();
                return this;
            });
        };

        import("Everlaw/Property").then((Property) => {
            return DeleteEntityConfirmation.confirmDeletion(
                new Property.CodeCategory(this).toString(),
                "category",
                "code",
                performDelete,
            );
        });
    }
    _remove() {
        this.codes.forEach((c: Code) => {
            c._remove(false);
        });
        Base.remove(this);
        Base.remove(Base.get(CodeOrder, this.id));
        Base.remove(
            Base.get("Rule").filter((rule) => (<AutoCodeRule>rule).designationId === this.id),
        );
    }
    override compare(other: Code | Category) {
        let order;
        if ((order = Base.get(CodeOrder, CodeOrder.CATEGORY_ORDER_ID))) {
            // if order exists yet
            if (other instanceof Category) {
                // if it's another category
                return order.sequence.indexOf(this.id) - order.sequence.indexOf(other.id);
            } else if (other.categoryId === this.id) {
                // if it's a code in this category
                return -1; // category first
            } else {
                // if it's a code in another category
                return order.sequence.indexOf(this.id) - order.sequence.indexOf(other.categoryId);
            }
        } else {
            // if the pref can't be fetched, fall back to alphabetical
            return this.display().localeCompare(other.display());
        }
    }

    descriptiveText(): HTMLSpanElement {
        return Dom.span({}, Dom.b(this.display()), " codes");
    }
    designationType(): Autocodable.Type {
        return Autocodable.Type.CATEGORY;
    }
}

/* TODO Refactor this to remove module namespace */
/* eslint-disable-next-line @typescript-eslint/no-namespace */
module Category {
    export type Id = number & Base.Id<"Category">;

    export function create(name: string) {
        return Rest.post("categories/create.rest", {
            name,
        }).then((data) => {
            Base.set(CodeOrder, data.categoryOrder);
            Base.set(CodeOrder, data.codeOrder);
            return Base.set(Category, data.category);
        });
    }

    export function byName(name: string) {
        name = Str.truncatedTrim(name, Code.MAX_NAME_LENGTH);
        return Base.get(Category).filter((c: Category) => {
            return c.name == name;
        })[0];
    }
}

export = Category;
