import Assignment = require("Everlaw/Assignment");
import Base = require("Everlaw/Base");
import DocumentGroupType = require("Everlaw/DocumentGroupType");
import Dom = require("Everlaw/Dom");
import Perm = require("Everlaw/PermissionStrings");
import QueryDialog = require("Everlaw/UI/QueryDialog");
import Rest = require("Everlaw/Rest");
import User = require("Everlaw/User");
import UserObject = require("Everlaw/UserObject");

class AssignmentGroup extends UserObject {
    get className() {
        return "AssignmentGroup";
    }
    override id: AssignmentGroup.Id;
    ownerId: User.Id;
    name: string;
    created: number;
    batchSize: number;
    assignments: Assignment[];
    // Number of assignments in the group, not counting the unassigned assignment.
    numAssignments = 0;
    size = 0;
    numReviewed: number;
    assignableSize: number;
    reviewCriteria: any[]; // EQL json
    inclusionCriteria: any[]; // EQL json
    dynamic: boolean;
    inclusionCriteriaBroken: boolean;
    initialized: boolean;
    groupBy: DocumentGroupType.Id; // can be null
    sort: string;
    savedLayoutId: number;
    constructor(params: any) {
        super(params);
        this._mixin(params);
    }
    override _mixin(params: any) {
        const previousAssignments = new Set(this.assignments);
        Object.assign(this, params);
        if (this.assignments && this.assignments.length) {
            this.assignments = Base.set(Assignment, this.assignments);
            for (const assignment of this.assignments) {
                previousAssignments.delete(assignment);
            }
            // When we serialize an assignment group update, all assignments are serialized.
            // So if the assignment is missing, it has been deleted.
            Base.remove([...previousAssignments.keys()]);
        }
    }
    override defaultLastActivity() {
        return this.created;
    }
    override display() {
        return this.name;
    }
    override compare(other: AssignmentGroup) {
        return this.created - other.created;
    }
    delete() {
        QueryDialog.create({
            title: "Delete assignment group",
            prompt: [
                Dom.div("Are you sure you want to delete this assignment group?"),
                Dom.br(),
                Dom.div(
                    "All documents in this assignment group will become unassigned. "
                        + "However, their ratings, codes, and binders will not change.",
                ),
                Dom.br(),
                Dom.div(Dom.b("This action cannot be undone.")),
            ],
            submitIsSafe: false,
            submitText: "Delete",
            onSubmit: () => {
                Rest.post("deleteAssignment.rest", { id: this.id }).then(() => Base.remove(this));
                return true;
            },
        });
    }
    rename(name: string): Promise<string> {
        return Rest.post("renameAssignment.rest", {
            id: this.id,
            name: name,
        }).then((data: string) => {
            this.name = data;
            Base.publish(this);
            for (const assignment of this.assignments) {
                assignment.name = data;
            }
            Base.publish(this.assignments);
            return this.name;
        });
    }

    /**
     * Publishes updates for self and assignments
     * @param layoutId id of layout that this group & it's assignments are now using
     */
    updateLayoutId(layoutId: number) {
        this.savedLayoutId = layoutId;
        this.assignments.forEach((a) => (a.savedLayoutId = layoutId));
        Base.publish(this);
        Base.publish(this.assignments);
    }
}

/* TODO Refactor this to remove module namespace */
/* eslint-disable-next-line @typescript-eslint/no-namespace */
module AssignmentGroup {
    export type Id = number & Base.Id<"AssignmentGroup">;

    export interface SizedAssignments {
        assignmentGroups: AssignmentGroup[];
        assignments: Assignment[];
    }

    /**
     * Return the list of Assignment Groups to which the current user can assign documents.
     */
    export function getAssignableGroups() {
        return Base.get(AssignmentGroup).filter((group) => {
            return (
                User.me.can(Perm.ADMIN, group, User.Override.ELEVATED)
                && group.initialized
                && !group.dynamic
            );
        });
    }
}

export = AssignmentGroup;
